import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Date Picker and Date Range components are UI elements that allow users to select a date or a range of dates from a calendar. These components are commonly used in applications that require date-based interactions, such as booking systems or scheduling applications.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/datepicker/guideline-datepicker-anatomy.png",
      "alt": "date picker anatomy"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Calendar`}</strong>{`: a visual representation of the days in a month or a week.`}</li>
      <li parentName="ol"><strong parentName="li">{`Month/Year Selector`}</strong>{`: allows the user to select a month and a year for the calendar.`}</li>
      <li parentName="ol"><strong parentName="li">{`Date Selection`}</strong>{`: allows the user to select a single date or a range of dates.`}</li>
      <li parentName="ol"><strong parentName="li">{`Apply Button`}</strong>{`: allows the user to apply their selected date or date range. (Optional)`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Date Picker and Date Range components are used in applications that require date-based interactions. They can be used in a variety of contexts, including:`}</p>
    <ul>
      <li parentName="ul">{`Booking systems: for selecting arrival and departure dates.`}</li>
      <li parentName="ul">{`Scheduling applications: for selecting dates and times of appointments or events.`}</li>
      <li parentName="ul">{`Data analysis tools: for selecting specific dates or date ranges to analyze data.`}</li>
    </ul>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>Use when the user needs to select a single date or a range of dates.</li>
      <li>Use when the context requires a date-based interaction.</li>
      <li>Use when the application requires a calendar view to display and select dates.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>Do not use when the user does not need to interact with dates.</li>
      <li>Do not use when the application does not require a calendar view.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Accessibility is an important consideration for any design system component. Here are some accessibility guidelines for the Date Picker and Date Range components:`}</p>
    <ul>
      <li parentName="ul">{`Provide clear labels and instructions for each field: Ensure that users can easily understand what each field is for, and provide clear instructions for how to use the component.`}</li>
      <li parentName="ul">{`Ensure keyboard accessibility: Ensure that users can navigate and interact with the component using only a keyboard. Use tabindex and aria attributes to enhance keyboard accessibility.`}</li>
      <li parentName="ul">{`Provide visual cues: Provide visual cues such as highlighting the selected date or date range, and using contrasting colors to help users with low vision.`}</li>
      <li parentName="ul">{`Test with assistive technologies: Test the component with screen readers and other assistive technologies to ensure that it is fully accessible.`}</li>
      <li parentName="ul">{`Provide alternative input methods: Consider providing alternative input methods such as voice input or a touch screen for users who may have difficulty using a keyboard or mouse.`}</li>
    </ul>
    <p>{`By following these accessibility guidelines, you can ensure that the Date Picker and Date Range components are accessible to all users, including those with disabilities.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Highlight the selected date or date range." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/datepicker/guideline-datepicker-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use color alone to indicate the selected date or date range." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/datepicker/guideline-datepicker-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Date Picker and Date Range include :`}</p>
    <ul>
      <li parentName="ul">{`Calendar view: displays the days in a month or week.`}</li>
      <li parentName="ul">{`Month/Year selector: allows the user to select a month and a year for the calendar.`}</li>
      <li parentName="ul">{`Date Selection: allows the user to select a single date or a range of dates.`}</li>
      <li parentName="ul">{`Apply button: allows the user to apply their selected date or date range.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Use a clear and concise design for the calendar view." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/datepicker/guideline-datepicker-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Overcomplicate the design with unnecessary features." aspectRatio="3:4" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/datepicker/guideline-datepicker-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      